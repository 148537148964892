<template>
  <div>
    <el-card
        shadow="never"
    >
      <div slot="header" style="height: 40px">
        <span style="line-height: 40px;font-size: 16px;">修改信息</span>
        <el-button type="primary" style="float: right" @click="goBack">返回</el-button>
      </div>

      <div style="padding-right: 32px;padding-left: 32px">
        <el-form
            label-position="right"
            label-width="80px"
            :rules="rules"
            ref="companyInfo"
            :model="companyInfo"
        >
          <el-row :gutter="24">
            <el-col :span="12">
              <el-row class="upload-img">
                <el-form-item label="公司logo" prop="companyLogo" id="companyLogo">
                  <el-upload
                      :class="{companyLogoUpload:showCompanyLogo,disCompanyLogoUpload:!showCompanyLogo}"
                      action="#"
                      list-type="picture-card"
                      :before-upload="companyLogoBeforeUpload"
                      :on-preview="companyLogoPreview"
                      :on-remove="companyLogoRemove"
                      :file-list="companyLogoImgList"
                      v-model="companyInfo.companyLogo"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="companyLogoVisible">
                    <img width="100%" :src="companyInfo.companyLogo" alt="">
                  </el-dialog>
                </el-form-item>
              </el-row>

              <el-row>
                <el-form-item label="公司名称" prop="companyName">
                  <el-input v-model="companyInfo.companyName"></el-input>
                </el-form-item>
              </el-row>

              <el-row>
                <el-form-item label="公司官网" prop="companyWebsite">
                  <el-input v-model="companyInfo.companyWebsite"></el-input>
                </el-form-item>
              </el-row>

              <el-row>
                <el-form-item label="成立时间" prop="establishTime">
                  <el-date-picker
                      style="width: 100%"
                      v-model="companyInfo.establishTime"
                      type="year"
                      value-format="yyyy"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-row>

              <!--              <el-row>
  <el-form-item label="企业规模" prop="companyScale">
    <el-select v-model="companyInfo.companyScale" style="width: 100%">
      <el-option
          v-for="item in companyScaleOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value">
      </el-option>
    </el-select>
  </el-form-item>
</el-row>-->
              <el-row>
                <el-form-item label="企业规模" prop="companyScale">
                  <el-input v-model="companyInfo.companyScale"></el-input>
                </el-form-item>
              </el-row>

              <el-row>
                <el-form-item label="注册资金" prop="registeredCapital">
                  <el-input v-model="companyInfo.registeredCapital"></el-input>
                </el-form-item>
              </el-row>
            </el-col>

            <el-col :span="12">
              <el-row class="upload-img">
                <el-form-item label="用户头像" prop="headImage" id="headImage">
                  <el-upload
                      :class="{headImageUpload:showHeadImage,disHeadImageUpload:!showHeadImage}"
                      action="#"
                      list-type="picture-card"
                      :before-upload="headImageBeforeUpload"
                      :on-preview="headImagePreview"
                      :on-remove="headImageRemove"
                      :file-list="headImageImgList"
                      v-model="companyInfo.headImage"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="headImageVisible">
                    <img width="100%" :src="companyInfo.headImage" alt="">
                  </el-dialog>
                </el-form-item>
              </el-row>

              <el-row>
                <el-form-item label="用户名称" prop="userName">
                  <el-input v-model="companyInfo.userName" disabled></el-input>
                </el-form-item>
              </el-row>

              <el-row>
                <el-form-item label="用户昵称" prop="nickName">
                  <el-input v-model="companyInfo.nickName"></el-input>
                </el-form-item>
              </el-row>

              <el-row>
                <el-form-item label="手机号" prop="phone">
                  <el-input v-model="companyInfo.phone" disabled></el-input>
                </el-form-item>
              </el-row>

              <el-row>
                <el-form-item label="电子邮箱" prop="email">
                  <el-input v-model="companyInfo.email"></el-input>
                </el-form-item>
              </el-row>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="企业地址" prop="companyAddress">
                <el-input v-model="companyInfo.companyAddress"></el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="24">
              <el-form-item label="公司简介" prop="companyIntroduction">
                <el-input
                    type="textarea"
                    autosize
                    :rows="5"
                    v-model="companyInfo.companyIntroduction"
                >
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>

          <el-row style="margin-left: 80px">
            <el-col :span="24">
              <el-checkbox v-model="checked">同意手机号和电子邮箱作为客服联系方式</el-checkbox>
            </el-col>
          </el-row>

          <div style="text-align: center;margin-top: 16px">
            <el-button type="primary" @click="submit('companyInfo')" style="width: 160px">提 交</el-button>
          </div>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import {
  Button,
  Card,
  Form,
  Row,
  Col,
  FormItem,
  Input,
  DatePicker,
  Select,
  Option,
  Upload,
  Checkbox,
  Dialog,
  Loading,
  Message
} from "element-ui";
import {getUserInfo} from "@/api/accountCenter";
import {uploadBusinessImage, updateUserInfo} from "@/api/accountCenter";

export default {
  components: {
    [Card.name]: Card,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [Button.name]: Button,
    [Row.name]: Row,
    [Col.name]: Col,
    [DatePicker.name]: DatePicker,
    [Select.name]: Select,
    [Option.name]: Option,
    [Upload.name]: Upload,
    [Checkbox.name]: Checkbox,
    [Dialog.name]: Dialog,
    [Message.name]: Message,
  },
  data() {
    return {
      headImageVisible: false,
      companyLogoVisible: false,
      companyInfo: {
        id: "",
        headImage: "",
        companyLogo: "",
        userName: "",
        nickName: "",
        phone: "",
        email: "",
        companyName: "",
        companyWebsite: "",
        companyAddress: "",
        establishTime: "",
        companyScale: "",
        registeredCapital: "",
        companyIntroduction: "",
      },
      headImageImgList: [],
      companyLogoImgList: [],
      showHeadImage: true,
      showCompanyLogo: true,
      rules: {
        userName: [
          {required: true, message: '请输入用户名称', trigger: 'blur'},
        ],
        userPassword: [
          {required: true, message: '请输入用户昵称', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/, message: '请输入正确的手机号码', trigger: 'change'}
        ],
        email: [
          {required: true, message: '请输入电子邮箱', trigger: 'blur'},
          {max: 100, message: '邮箱长度需小于100个字符', trigger: 'change',},
          {
            pattern: /^\s*\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*\s*$/,
            message: '请输入正确的包含公司邮箱后缀的邮箱',
            trigger: 'change',
          },
        ],
        companyName: [
          {required: true, message: '请输入公司名称', trigger: 'blur'}
        ],
        companyWebsite: [
          {required: false, message: '请输入公司官网', trigger: 'blur'}
        ],
        establishTime: [
          {required: true, message: '请选择成立时间', trigger: 'blur'}
        ],
        companyScale: [
          {required: true, message: '请输入企业规模', trigger: 'blur'}
        ],
        registeredCapital: [
          {required: true, message: '请输入注册资金', trigger: 'blur'}
        ],
        companyAddress: [
          {required: true, message: '请输入企业地址', trigger: 'blur'}
        ],
        companyIntroduction: [
          {required: true, message: '请输入公司简介', trigger: 'blur'}
        ],
        headImage: [
          {required: false, message: '请上传用户头像'}
        ],
        companyLogo: [
          {required: false, message: '请上传公司logo'}
        ],
      },
      checked: false,
      headImageLoad: undefined,
      companyLogoLoad: undefined,
    }
  },
  mounted() {
    this.getCompanyInfo();
  },
  methods: {
    getCompanyInfo() {
      getUserInfo().then(res => {
        this.companyInfo.id = res.data.companyId;
        if (res.data.headImage) {
          this.companyInfo.headImage = res.data.headImage;
          let img = {
            name: 'headImage',
            url: this.companyInfo.headImage
          }
          this.headImageImgList.push(img);
        }
        if (res.data.companyLogo) {
          this.companyInfo.companyLogo = res.data.companyLogo;
          let img = {
            name: 'companyLogo',
            url: this.companyInfo.companyLogo
          }
          this.companyLogoImgList.push(img);
        }
        this.showHeadImage = this.headImageImgList.length < 1;
        this.showCompanyLogo = this.companyLogoImgList.length < 1;
        this.companyInfo.userName = res.data.userName;
        this.companyInfo.nickName = res.data.nickName;
        this.companyInfo.phone = res.data.mobile;
        this.companyInfo.email = res.data.email;
        this.companyInfo.companyName = res.data.companyName;
        this.companyInfo.companyWebsite = res.data.companyWebsite;
        this.companyInfo.establishTime = res.data.foundingTime;
        this.companyInfo.companyScale = res.data.companyScale;
        this.companyInfo.registeredCapital = res.data.registeredFunds;
        this.companyInfo.companyAddress = res.data.address;
        this.companyInfo.companyIntroduction = res.data.companyIntroduction;
      })
    },

    headImageBeforeUpload(file) {
      this.headImageLoad = Loading.service({
        target: document.getElementById('headImage'),
        text: '上传中...'
      })
      const formData = new FormData();
      formData.append("files", file);
      uploadBusinessImage(formData)
          .then((res) => {
            this.companyInfo.headImage = res.data;
            let img = {
              name: file.name,
              url: this.companyInfo.headImage
            }
            this.headImageImgList.push(img);
            this.showHeadImage = this.headImageImgList.length < 1;
            this.$refs.companyInfo.validateField('headImage');
            this.headImageLoad.close();
          }).catch(() => {
        this.headImageLoad.close();
        this.$message.error("上传失败");
      });
      return false;
    },
    headImagePreview() {
      this.headImageVisible = true;
    },
    headImageRemove() {
      this.headImageImgList.pop();
      this.companyInfo.headImage = "";
      this.$refs.companyInfo.validateField('headImage')
      this.showHeadImage = !this.showHeadImage;
    },

    companyLogoBeforeUpload(file) {
      this.companyLogoLoad = Loading.service({
        target: document.getElementById('companyLogo'),
        text: '上传中...'
      })
      const formData = new FormData();
      formData.append("files", file);
      uploadBusinessImage(formData).then((res) => {
        this.companyInfo.companyLogo = res.data;
        let img = {
          name: file.name,
          url: this.companyInfo.companyLogo
        }
        this.companyLogoImgList.push(img);
        this.showCompanyLogo = this.companyLogoImgList.length < 1;
        this.$refs.companyInfo.validateField('companyLogo');
        this.companyLogoLoad.close();
      }).catch(() => {
        this.companyLogoLoad.close();
        this.$message.error("上传失败");
      });
      return false;
    },
    companyLogoPreview() {
      this.companyLogoVisible = true;
    },
    companyLogoRemove() {
      this.companyLogoImgList.pop();
      this.companyInfo.companyLogo = "";
      this.$refs.companyInfo.validateField('companyLogo')
      this.showCompanyLogo = !this.showCompanyLogo;
    },

    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.checked) {
            let updateInfoParam = {
              companyId: this.companyInfo.id,
              headImage: this.companyInfo.headImage,
              companyLogo: this.companyInfo.companyLogo,
              userName: this.companyInfo.userName,
              nickName: this.companyInfo.nickName,
              mobile: this.companyInfo.phone,
              email: this.companyInfo.email,
              companyName: this.companyInfo.companyName,
              companyWebsite: this.companyInfo.companyWebsite,
              address: this.companyInfo.companyAddress,
              foundingTime: this.companyInfo.establishTime,
              companyScale: this.companyInfo.companyScale,
              registeredFunds: this.companyInfo.registeredCapital,
              companyIntroduction: this.companyInfo.companyIntroduction,
              agree: true,
            };
            updateUserInfo(updateInfoParam).then(res => {
              if (res.code === 200) {
                this.$router.push({
                  name: 'showInformation'
                });
                Message({
                  message: '信息修改成功',
                  type: 'success'
                });
              }
            })
          } else {
            Message.closeAll();
            Message({
              message: '请先勾选底部同意手机号和电子邮箱作为客服联系方式',
              type: 'warning'
            });
          }
        }
      })
    },
    goBack() {
      this.$router.back()
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 161px;
  height: 161px;
}

.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 162px;
  height: 162px;
}

.avatar-uploader:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 162px;
  height: 162px;
  line-height: 162px;
  text-align: center;
}

.avatar {
  width: 162px;
  height: 162px;
  display: block;
}

.upload-img {
  /deep/ .el-form-item__label {
    line-height: 162px !important;
  }
}

.headImageUpload {
  height: 162px;

  /deep/ .el-upload--picture-card {
    width: 162px;
    height: 162px;
    line-height: 162px;
  }
}

.disHeadImageUpload {
  height: 162px;

  /deep/ .el-upload--picture-card {
    display: none;
  }
}

.companyLogoUpload {
  height: 162px;

  /deep/ .el-upload--picture-card {
    width: 162px;
    height: 162px;
    line-height: 162px;
  }
}

.disCompanyLogoUpload {
  height: 162px;

  /deep/ .el-upload--picture-card {
    display: none;
  }
}
</style>
